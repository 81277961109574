/**
 * Страницы, которые должны быть добавлены в SiteMap
 * комментарии [START-PARSE] и [END-PARSE]
 * нужны скрипту по созданию путей рендеров.
 * Они указывают где начинать и где заканчивать брать пути для формирования рендеров
 * Внутренние комментарии // пропускаются скриптом
 * Скрипты сидят тут: \fast-angular\scripts
 */
export const seoRoutes: string[] = [
  /*[[START-PARSE]]*/
  '/',
  '/faq',
  '/tos',
  '/privacy-policy',
  '/game/fast',
  '/game/classic',
  '/game/classic/history',
  '/game/x50',
  '/game/double',
  '/game/crash',
  '/game/king',
  '/game/craft',
  '/game/slot',
  '/game/match-bets',
  '/game/cases',
  '/game/fortune-wheel',
  '/game/tower',
  '/game/hilo',
  '/game/baccarat',
  '/game/baccarat/history',
  '/game/case-battle',
  '/game/poggi',
  '/history/fast',
  '/history/crash',
  '/history/double',
  '/hell-case',
  '/key-drop',
  '/skin-club',
  '/csgo-roll',
  '/farm-skins',
  '/events',
  /*[[END-PARSE]]*/
];
