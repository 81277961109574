import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-gleam-modal',
  templateUrl: './gleam-modal.component.html',
  styleUrls: ['./gleam-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIcon],
})
export class GleamModalComponent {
  readonly #dialogRef = inject(MatDialogRef<GleamModalComponent>);

  onClose(): void {
    this.#dialogRef.close();
  }
}
