import { inject, Injectable, Injector } from '@angular/core';
import type { ModalModel } from '@dev-fast/types';
import { ModalNames, RoutableModalNames } from '@dev-fast/types';
import { FilterMobileMenuComponent } from '@dev-fast/ui-components';

import { AuthModalV2Component } from '@app/auth';
import { ModalsService } from '@app/core/modal-service';
import { FeatureFlags, FeatureFlagsService } from '@app/feature-flags';
import { P2pMarketHistoryComponent, P2pMarketModalComponent } from '@app/modules/p2p-new';
import { BanComponent } from '@app/shared/modals/ban';
import { CaseBonusModalComponent } from '@app/shared/modals/case-bonus-modal';
import { RoutableModalContainerComponent } from '@app/shared/modals/containers/routable-modal-container';
import { GleamModalComponent } from '@app/shared/modals/gleam-modal';
import { P2pMarketRWTSAComponent } from '@app/shared/modals/market-rwt';
import { ReferralModalComponent } from '@app/shared/modals/referral-modal';
import { SelfExclusionModalComponent } from '@app/shared/modals/self-exclusion-modal';
import { TechSupportComponent } from '@app/shared/modals/tech-support';
import { TradeModalComponent } from '@app/shared/modals/trade-modal';
import { TransactionsNotificationModalComponent } from '@app/shared/modals/transactions-notification-modal';
import { UserProfileComponent } from '@app/shared/modals/user-profile';
import { UserProfileNewComponent } from '@app/shared/modals/user-profile-new';
import { P2pErrorsDescriptionComponent } from '@app/widgets/p2p-new';
import { P2pNewAuctionCompletedModalComponent, P2pNewSetupModalComponent } from '@app/widgets/p2p-new/modals';
import { TradeCompleteService } from '@app/widgets/p2p-new/modals/p2p-auction-completed-modal/p2p-trade-complete.service';
import { SihCheckComponent } from '@app/widgets/sih';

export const NewProfileModalConfig = {
  panelClass: 'user-profile-modal',
  autoFocus: false,
  width: '100%',
};

export const OldProfileModalConfig = {
  panelClass: 'user-profile-modal',
  autoFocus: false,
};

@Injectable()
export class MainModalsService {
  readonly #commonModalsService = inject(ModalsService);
  readonly #featureFlagsService = inject(FeatureFlagsService);
  readonly #injector = inject(Injector);

  readonly #modals: ModalModel[] = [
    {
      name: ModalNames.USER_PROFILE,
      component: this.#featureFlagsService.isFeatureFlagEnabled([FeatureFlags.NEW_PROFILE])
        ? UserProfileNewComponent
        : UserProfileComponent,
      config: this.#featureFlagsService.isFeatureFlagEnabled([FeatureFlags.NEW_PROFILE]) ? NewProfileModalConfig : OldProfileModalConfig,
    },

    {
      name: ModalNames.AUTH,
      component: AuthModalV2Component,
      config: {
        height: 'auto',
        maxWidth: 'inherit',
        panelClass: 'auth-modal-v2',
        mobileViewAllowed: true,
      },
    },
    {
      name: ModalNames.TECH_SUPPORT_SELECT_METHOD,
      component: TechSupportComponent,
      config: {
        height: 'auto',
        panelClass: 'tech-support-modal',
        autoFocus: false,
      },
    },

    {
      name: ModalNames.SELF_EXCLUSION,
      component: SelfExclusionModalComponent,
      config: {
        height: 'auto',
        panelClass: 'self-exclusion-modal',
      },
    },
    {
      name: ModalNames.REFERRAL,
      component: ReferralModalComponent,
      config: {
        height: 'auto',
        panelClass: 'referral-modal',
        backdropClass: 'referral-modal-backdrop',
      },
    },
    {
      name: ModalNames.CASES_BONUS,
      component: CaseBonusModalComponent,
      config: {
        height: 'auto',
        panelClass: 'case-bonus-modal',
        backdropClass: 'referral-modal-backdrop',
      },
    },
    {
      name: ModalNames.P2P_SETUP,
      component: P2pNewSetupModalComponent,
      config: {
        panelClass: 'p2p-setup-modal',
      },
    },
    {
      name: ModalNames.P2P_ERRORS_DESCRIPTION,
      component: P2pErrorsDescriptionComponent,
      config: {},
    },
    {
      name: ModalNames.SIH_INSTALL,
      component: SihCheckComponent,
      config: {
        panelClass: 'sih-check-modal',
        mobileViewAllowed: true,
        autoFocus: false,
      },
    },
    {
      name: ModalNames.P2P_AUCTION_COMPLETED,
      component: P2pNewAuctionCompletedModalComponent,
      config: {
        panelClass: 'p2p-auction-completed-modal',
        injector: Injector.create({
          providers: [TradeCompleteService],
          parent: this.#injector,
        }),
      },
    },
    {
      name: ModalNames.P2P_KIT_DETAILS,
      component: P2pMarketModalComponent,
      config: {
        panelClass: 'p2p-kits-details-modal',
        maxWidth: '100vw',
      },
    },
    {
      name: ModalNames.P2P_FILTER_MOBILE_MENU,
      component: FilterMobileMenuComponent,
      config: {
        panelClass: 'p2p-filter-mobile-menu',
        maxWidth: '100vw',
        maxHeight: '100vh',
      },
    },
    {
      name: ModalNames.GLEAM,
      component: GleamModalComponent,
      config: {
        panelClass: 'gleam-modal',
        maxHeight: '100vh',
        minWidth: '100vw',
        height: 'calc(var(--vh, 1vh) * 100)',
        width: '100vw',
        autoFocus: false,
      },
    },
    {
      name: ModalNames.BAN,
      component: BanComponent,
      config: {
        panelClass: 'ban-modal',
        maxWidth: '95vw',
        autoFocus: false,
        disableClose: true,
      },
    },
    {
      name: ModalNames.P2P_MARKET_HISTORY,
      component: P2pMarketHistoryComponent,
      config: {
        height: 'calc(var(--vh, 1vh) * 100)',
        width: '100vw',
        hasBackdrop: true,
        panelClass: 'common-routable-modal',
        backdropClass: ['blurred', 'translucent'],
      },
    },
    {
      name: ModalNames.TRADE,
      component: TradeModalComponent,
      config: {
        height: 'calc(var(--vh, 1vh) * 100)',
        width: '100vw',
      },
    },
    {
      name: ModalNames.TRANSACTION_NOTIFICATION,
      component: TransactionsNotificationModalComponent,
      config: {},
    },
    {
      name: ModalNames.P2P_RWT,
      component: P2pMarketRWTSAComponent,
      config: {
        disableClose: true,
        hasBackdrop: true,
        backdropClass: ['blurred', 'transparent'],
      },
    },
    ///////// Неиспользуемые модалки, но оставлены на всякий случай
    // {
    //   name: ModalNames.DDOS,
    //   component: DdosModalComponent,
    //   config: {
    //     panelClass: 'ddos-modal',
    //     height: '95vh',
    //     width: '95vw',
    //     maxWidth: '95vw',
    //   },
    // },
    // {
    //   name: ModalNames.STEAM_ERROR,
    //   component: SteamErrorModalComponent,
    //   config: {
    //     height: 'auto',
    //     panelClass: 'steam-error-modal',
    //     autoFocus: false,
    //   },
    // },
  ];

  readonly #routableModals: Array<ModalModel<RoutableModalNames>> = [
    {
      name: RoutableModalNames.COMMON,
      component: RoutableModalContainerComponent,
      config: {
        closeOnNavigation: false,
        hasBackdrop: false,
        disableClose: true,
        height: '100vh',
        width: '100vw',
        minWidth: '100vw',
      },
    },
  ];

  registerModals(): void {
    this.registerRoutableModals();
    this.#commonModalsService.registerModals(this.#modals);
  }

  registerRoutableModals(): void {
    this.#commonModalsService.registerModals(this.#routableModals);
  }
}
