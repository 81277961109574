import { AsyncPipe, DatePipe } from '@angular/common';
import type { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { UserApiService } from '@dev-fast/backend-services';
import type { IPublicGameStatistic, IPublicUserDetailed } from '@dev-fast/types';
import { CLOUD_DOMAIN, GameMode, SocialType } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import type { Observable } from 'rxjs';
import { map, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { GamesState } from '@app/core/state/games-store';
import { UserAvatarComponent } from '@app/ui/components/index';

import { BestCardComponent } from './best-card/best-card.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  standalone: true,
  imports: [AsyncPipe, DatePipe, NgxSkeletonLoaderModule, TranslateModule, MatIconModule, UserAvatarComponent, BestCardComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent {
  readonly #userApiService = inject(UserApiService);
  readonly #store = inject(Store);
  readonly #dialogRef = inject(MatDialogRef<UserProfileComponent>);
  readonly #data = inject<{ userId: number }>(MAT_DIALOG_DATA);

  readonly userId = this.#data.userId;
  readonly hiddenItemsArr: unknown[] = new Array(6);
  readonly hiddenItem: IPublicGameStatistic = {
    key: 'baccarat',
    mode: GameMode.PVE,
    top: {
      isCurrency: true,
      key: 'baccarat_max_bet',
      title: 'Max bet',
      value: '9999',
    },
  };

  readonly socialTypes = SocialType;

  readonly user$ = this.#userApiService
    .getUserProfile<IPublicUserDetailed>(this.userId, { needDetailedInfo: true })
    .pipe(catchError((error: HttpErrorResponse) => of(null)));

  getGameTitle(key: string): Observable<string | undefined> {
    return this.#store.select(GamesState.gameTitle).pipe(map((filterFn) => filterFn(key)));
  }

  close(): void {
    this.#dialogRef.close();
  }

  getUserCountry(user: IPublicUserDetailed): string {
    return user.country ? `//${CLOUD_DOMAIN}/country-flags/${user.country.toLowerCase()}.png` : '';
  }
}
